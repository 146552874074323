'use strict';

/*******************************************************************************************/
function svgMarker() {
	let propsPath1 = {};
	propsPath1["className"] = "marker";
	propsPath1["d"] = "m730.940002,1839.630005c-38.765991,-190.300049 -107.116028,-348.670044 -189.903015,-495.440063c-61.406982,-108.869995 -132.543976,-209.359985 -198.363983,-314.939941c-21.972015,-35.242981 -40.93399,-72.476013 -62.046997,-109.052979c-42.216003,-73.137024 -76.444,-157.934998 -74.269012,-267.932007c2.125,-107.473022 33.208008,-193.684021 78.029999,-264.172028c73.718994,-115.934998 197.201019,-210.988983 362.884003,-235.968994c135.466003,-20.423996 262.474976,14.082001 352.54303,66.748001c73.596008,43.03801 130.596008,100.526993 173.915955,168.280014c45.219971,70.716003 76.359985,154.259979 78.969971,263.231964c1.340088,55.830017 -7.799927,107.532043 -20.679932,150.41803c-13.030029,43.408997 -33.98999,79.695007 -52.640015,118.453979c-36.410034,75.658997 -82.050049,144.984009 -127.859985,214.343994c-136.437012,206.609985 -264.496033,417.310059 -320.580017,706.030029z";
	propsPath1["strokeWidth"] = "37";
	propsPath1["strokeMiterlimit"] = "10";
	propsPath1["stroke"] = "#000000";
	propsPath1["fillRule"] = "evenodd";
	propsPath1["fill"] = "#31A17D";
	propsPath1["clipRule"] = "evenodd";
	
	let propsPath2 = {};
	propsPath2["d"] = "m731.379028,1934.410034c131.603943,-120.869995 211.313965,-235.97998 269.140991,-350.48999c42.890015,-84.950073 70.540039,-169.170044 106.949951,-254.060059c12.160034,-28.339966 28.51001,-56.569946 42.52002,-85.299927c28.02002,-57.450073 72.660034,-118.140015 169.030029,-178.100098c94.160034,-58.589966 193.949951,-93.755981 291.579956,-114.69696c160.560059,-34.442017 344.280029,-37.299011 502.589966,15.593018c129.449951,43.243958 204.680176,113.463928 233.780029,178.863953c23.780029,53.440063 21.429932,108.180054 -1.040039,163.099976c-23.459961,57.320068 -69.569824,116.070068 -161.109863,177.390015c-46.900146,31.420044 -98.900146,56.340088 -146.410034,74.869995c-48.090088,18.76001 -96.599976,30.390015 -145.330078,44.320068c-95.119995,27.189941 -192.419922,47.150024 -289.890015,67.079956c-290.319946,59.339966 -577.25,124.320068 -871.810913,261.430054z";
	propsPath2["fillRule"] = "evenodd";
	propsPath2["fill"] = "#ccc";
	propsPath2["clipRule"] = "evenodd";
	
	let propsCircle = {};
	propsCircle["r"] = "183.333";
	propsCircle["cy"] = "651.047";
	propsCircle["cx"] = "729.546";
	propsCircle["fillRule"] = "evenodd";
	propsCircle["fill"] = "pink";
	propsCircle["clipRule"] = "evenodd";
	
	return (
		<svg width="2481" height="2073" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2481 2073">
			<g>
				<path {...propsPath1} />
				<path {...propsPath2} />
				<circle {...propsCircle} />
			</g>
		</svg>
	);
};

/*******************************************************************************************/
function svgMarker_netzwerk() {
	
	
	let propsPath1 = {
		"fill": "#5837B7",
		"d": "M25,1H9C5.1,1,2,4.1,2,8v16c0,3.9,3.1,7,7,7c0,0,1.5,0,3.5,0C14,31,17,44,17,44s3.7-13,5-13c1.8,0,3,0,3,0 c3.9,0,7-3.1,7-7V8C32,4.1,28.9,1,25,1z",
	};
	let propsPath2 = {
		"fill": "none",
		"stroke": "#000000",
		"strokeWidth": "0.8",
		"strokeMiterlimit": "10",
		"d": "M25,1H9C5.1,1,2,4.1,2,8v16c0,3.9,3.1,7,7,7c0,0,1.5,0,3.5,0C14,31,17,44,17,44s3.7-13,5-13c1.8,0,3,0,3,0 c3.9,0,7-3.1,7-7V8C32,4.1,28.9,1,25,1z",
	};
	
	let style3 = {
		"fill": "#FFFFFF",
	};
	let style4 = {
		"fill": "#FFFFFF",
		"stroke": "#FFFFFF",
		"strokeMiterlimit": "10",
	};
	
	let propsCircle1 = _.assign({}, style3);
	propsCircle1["cx"] = "12.4";
	propsCircle1["cy"] = "11.4";
	propsCircle1["r"] = "2.4";
	
	let propsCircle2 = _.assign({}, style3);
	propsCircle2["cx"] = "21.1";
	propsCircle2["cy"] = "8";
	propsCircle2["r"] = "2.4";
	
	let propsCircle3 = _.assign({}, style3);
	propsCircle3["cx"] = "18";
	propsCircle3["cy"] = "20.8";
	propsCircle3["r"] = "4.7";
	
	let propsLine4 = _.assign({}, style4);
	propsLine4["x1"] = "10.3";
	propsLine4["y1"] = "12.2";
	propsLine4["x2"] = "21.7";
	propsLine4["y2"] = "7.8";
	
	let propsLine5 = _.assign({}, style4);
	propsLine5["x1"] = "16.7";
	propsLine5["y1"] = "21";
	propsLine5["x2"] = "11.7";
	propsLine5["y2"] = "9.8";
	
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 45">
			<g>
				<g>
					<g>
						<path {...propsPath1} />
					</g>
					<g>
						<path {...propsPath2} />
					</g>
				</g>
			</g>
			<g>
				<g>
					<circle {...propsCircle1} />
					<circle {...propsCircle2} />
					<circle {...propsCircle3} />
					<line {...propsLine4} />
					<line {...propsLine5} />
				</g>
			</g>
		</svg>
	);
};

/*******************************************************************************************/
function svgMarker_netzwerk_inner() {
	
	let style3 = {
		"stroke": "#000000",
		"strokeMiterlimit": "10",
	};
	let style4 = {
		"stroke": "#000000",
		"strokeWidth": "17.6162",
		"strokeMiterlimit": "10",
	};
	
	let propsCircle1 = _.assign({}, style3);
	propsCircle1["cx"] = "47.9";
	propsCircle1["cy"] = "106.9";
	propsCircle1["r"] = "42.3";
	
	let propsCircle2 = _.assign({}, style3);
	propsCircle2["cx"] = "201.2";
	propsCircle2["cy"] = "47";
	propsCircle2["r"] = "42.3";
	
	let propsCircle3 = _.assign({}, style3);
	propsCircle3["cx"] = "146.5";
	propsCircle3["cy"] = "272.5";
	propsCircle3["r"] = "82.8";
	
	let propsLine4 = _.assign({}, style4);
	propsLine4["d"] = "M10.9,121l200.8-77.5";
	
	let propsLine5 = _.assign({}, style4);
	propsLine5["d"] = "M123.6,276L35.6,78.7";
	
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.4 361.8">
			<g>
				<g>
					<circle {...propsCircle1} />
					<circle {...propsCircle2} />
					<circle {...propsCircle3} />
					<path {...propsLine4} />
					<path {...propsLine5} />
				</g>
			</g>
		</svg>
	);
};

